import React from 'react';
import './header.css';
import negativLogo from '../assets/logo_negativ.png';

const Header = () => {
  return (
    <div className="header">
      <img src={negativLogo} className="Header-logo" alt="logo" />
    </div>
  );
};

export default Header;
