import React from 'react';
import './content.css';
import Bild from '../assets/bannerImage.jpg';

const ContentWithImg = (content: any) => {
  return (
    <div className="content-outer">
      <div className="container">
        <div className="content">
          <h1>
            {`${content.content.title}`}
            {/* <br />
            {`${content.content.titleAddition}`} */}
          </h1>
          <div className="content-wrapper">
            <div className="content-wrapper__Inner">
              <p>
                {' '}
                Mit unseren DienstLeistungen unterstützen wir hauptsächlich
                Unternehmungen der Fahrzeug­branche. Gerne unterstützen wir auch
                Sie in der Unternehmensführung und Organisation:
              </p>
              <ul>
                <li>Analyse und Beratung</li>
                <li>Sparring-Partner für Unternehmer oder Führungspersonen</li>
                <li>Geschäftsentwicklung</li>
                <li>Coaching für Führungspersonen</li>
                <li>Projektmanagement</li>
                <li>Weitere Managementaufgaben</li>
              </ul>
            </div>
            <div className="image-wrapper__Outer">
              <div
                className="image-wrapper__Inner"
                style={{
                  backgroundImage: `url("${Bild}")`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWithImg;
