import './App.css';
import Header from './components/header';
import Content from './components/contentWithImg';
import Footer from './components/footer';

//build2
function App() {
  const content = {
    title: 'DienstLeister der Fahrzeugbranche!',
    // titleAddition: 'Ihre Ohren in besten Händen',
    paragraph:
      'Am 15.02.2021 eröffne ich gemeinsam mit meiner Frau das Akustik - Fachgeschäft Hörberatung Schuler, in Rorschach. Meine jahrelangen Erfahrungen als Fachgeschäfts- und Verkaufsleiter bei diversen, grossen Akustikfirmen möchte ich nun in unserem eigenen Fachgeschäft nutzen, um möglichst vielen Menschen zu einem besseren Hören zu verhelfen. Dabei stehen die Wünsche und Bedürfnisse meiner Kundinnen und Kunden an erste Stelle.',
  };
  return (
    <div className="App">
      <Header />
      {/* <Banner /> */}
      <Content content={content} />
      {/* <ContactContent />
      <Map /> */}
      <Footer />
    </div>
  );
}

export default App;
