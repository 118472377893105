import React from 'react';
import './footer.css';
import negativLogo from '../assets/logo_negativ.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container footer-wrapper">
        <div className="footer-col">
          <p>Jo WEHRLE GmbH</p>
          <p>In der Breiti 20</p>
          <p>9244 Niederuzwil</p>
        </div>
        <div className="footer-col">
          <p>T: +41 79 735 21 36</p>
          <a href="mailto:johann.wehrle@jowehrle.ch">
            M: johann.wehrle@jowehrle.ch
          </a>
          <a href="https://jowehrle.ch">www.jowehrle.ch</a>
        </div>
        <div className="footer-col">
          <img src={negativLogo} className="footer-logo" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
